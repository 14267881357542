var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"mt-306 added-data"},[_c('div',{staticClass:"adduser-btn-row"},[_c('h4',{staticClass:"card-title"},[_vm._v(_vm._s(_vm.$t("ReferenceDataMenu.ReferenceData")))])]),_c('b-row',[(
        _vm.checkAbility({
          action: _vm.constants.PERMISSIONS_ACTION.MENU,
          subject: _vm.constants.PERMISSIONS_MODEL.GEOFENCES
        })
      )?_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-link',{attrs:{"to":"/geofences"}},[_c('div',{staticClass:"link-data-card"},[_c('GeoImg',{staticClass:"link-icon"}),_c('div',{staticClass:"menu-list-data"},[_vm._v(" "+_vm._s(_vm.$t("ReferenceDataMenu.Geofences"))+" "),_c('feather-icon',{staticClass:"mr-1",attrs:{"icon":"ChevronsRightIcon","size":"20"}})],1)],1)])],1):_vm._e(),(
        _vm.checkAbility({
          action: _vm.constants.PERMISSIONS_ACTION.MENU,
          subject: _vm.constants.PERMISSIONS_MODEL.ROUTES
        })
      )?_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-link',{attrs:{"to":"/routes"}},[_c('div',{staticClass:"link-data-card"},[_c('RouteImg',{staticClass:"link-icon"}),_c('div',{staticClass:"menu-list-data"},[_vm._v(" "+_vm._s(_vm.$t("ReferenceDataMenu.Routes"))+" "),_c('feather-icon',{staticClass:"mr-1",attrs:{"icon":"ChevronsRightIcon","size":"20"}})],1)],1)])],1):_vm._e(),(
        _vm.checkAbility({
          action: _vm.constants.PERMISSIONS_ACTION.MENU,
          subject: _vm.constants.PERMISSIONS_MODEL.ALERTS
        })
      )?_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-link',{attrs:{"to":"/alerts"}},[_c('div',{staticClass:"link-data-card"},[_c('AlertsImg',{staticClass:"link-icon"}),_c('div',{staticClass:"menu-list-data"},[_vm._v(" "+_vm._s(_vm.$t("ReferenceDataMenu.Alerts"))+" "),_c('feather-icon',{staticClass:"mr-1",attrs:{"icon":"ChevronsRightIcon","size":"20"}})],1)],1)])],1):_vm._e(),(
        _vm.checkAbility({
          action: _vm.constants.PERMISSIONS_ACTION.MENU,
          subject: _vm.constants.PERMISSIONS_MODEL.LABELS
        })
      )?_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-link',{attrs:{"to":"/groups"}},[_c('div',{staticClass:"link-data-card"},[_c('GroupImg',{staticClass:"link-icon"}),_c('div',{staticClass:"menu-list-data"},[_vm._v(" "+_vm._s(_vm.$t("ReferenceDataMenu.Groups"))+" "),_c('feather-icon',{staticClass:"mr-1",attrs:{"icon":"ChevronsRightIcon","size":"20"}})],1)],1)])],1):_vm._e(),(
        _vm.checkAbility({
          action: _vm.constants.PERMISSIONS_ACTION.MENU,
          subject: _vm.constants.PERMISSIONS_MODEL.OPERATORS
        })
      )?_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-link',{attrs:{"to":"/operators"}},[_c('div',{staticClass:"link-data-card"},[_c('OperatorsImg',{staticClass:"link-icon"}),_c('div',{staticClass:"menu-list-data"},[_vm._v(" "+_vm._s(_vm.$t("ReferenceDataMenu.Operators"))+" "),_c('feather-icon',{staticClass:"mr-1",attrs:{"icon":"ChevronsRightIcon","size":"20"}})],1)],1)])],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }