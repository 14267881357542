<template>
  <b-card class="mt-306 added-data">
    <div class="adduser-btn-row">
      <h4 class="card-title">{{ $t("ReferenceDataMenu.ReferenceData") }}</h4>
    </div>
    <b-row>
      <b-col
        cols="12"
        md="6"
        lg="4"
        v-if="
          checkAbility({
            action: constants.PERMISSIONS_ACTION.MENU,
            subject: constants.PERMISSIONS_MODEL.GEOFENCES
          })
        "
      >
        <b-link to="/geofences">
          <div class="link-data-card">
            <GeoImg class="link-icon" />
            <div class="menu-list-data">
              {{ $t("ReferenceDataMenu.Geofences") }}
              <feather-icon class="mr-1" icon="ChevronsRightIcon" size="20" />
            </div>
          </div>
        </b-link>
      </b-col>
      <b-col
        cols="12"
        md="6"
        lg="4"
        v-if="
          checkAbility({
            action: constants.PERMISSIONS_ACTION.MENU,
            subject: constants.PERMISSIONS_MODEL.ROUTES
          })
        "
      >
        <b-link to="/routes">
          <div class="link-data-card">
            <RouteImg class="link-icon" />
            <div class="menu-list-data">
              {{ $t("ReferenceDataMenu.Routes") }}
              <feather-icon class="mr-1" icon="ChevronsRightIcon" size="20" />
            </div>
          </div>
        </b-link>
      </b-col>
      <b-col
        cols="12"
        md="6"
        lg="4"
        v-if="
          checkAbility({
            action: constants.PERMISSIONS_ACTION.MENU,
            subject: constants.PERMISSIONS_MODEL.ALERTS
          })
        "
      >
        <b-link to="/alerts">
          <div class="link-data-card">
            <AlertsImg class="link-icon" />
            <div class="menu-list-data">
              {{ $t("ReferenceDataMenu.Alerts") }}
              <feather-icon class="mr-1" icon="ChevronsRightIcon" size="20" />
            </div>
          </div>
        </b-link>
      </b-col>
      <b-col
        cols="12"
        md="6"
        lg="4"
        v-if="
          checkAbility({
            action: constants.PERMISSIONS_ACTION.MENU,
            subject: constants.PERMISSIONS_MODEL.LABELS
          })
        "
      >
        <b-link to="/groups">
          <div class="link-data-card">
            <GroupImg class="link-icon" />
            <div class="menu-list-data">
              {{ $t("ReferenceDataMenu.Groups") }}
              <feather-icon class="mr-1" icon="ChevronsRightIcon" size="20" />
            </div>
          </div>
        </b-link>
      </b-col>
      <b-col
        cols="12"
        md="6"
        lg="4"
        v-if="
          checkAbility({
            action: constants.PERMISSIONS_ACTION.MENU,
            subject: constants.PERMISSIONS_MODEL.OPERATORS
          })
        "
      >
        <b-link to="/operators">
          <div class="link-data-card">
            <OperatorsImg class="link-icon" />
            <div class="menu-list-data">
              {{ $t("ReferenceDataMenu.Operators") }}
              <feather-icon class="mr-1" icon="ChevronsRightIcon" size="20" />
            </div>
          </div>
        </b-link>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BLink, BButton, BCard, BRow, BCol } from "bootstrap-vue";
import AlertsImg from "@/assets/images/pages/alerts.svg";
import GroupImg from "@/assets/images/pages/group.svg";
import OperatorsImg from "@/assets/images/pages/operators.svg";
import GeoImg from "@/assets/images/pages/geo-card.svg";
import RouteImg from "@/assets/images/pages/route.svg";

export default {
  components: {
    BLink,
    BButton,
    BCard,
    BRow,
    BCol,
    AlertsImg,
    OperatorsImg,
    GroupImg,
    GeoImg,
    RouteImg
  },
  data() {
    return {};
  }
};
</script>
<style lang="scss">
.link-data-card {
  min-height: 210px;
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
  padding: 15px;
  display: flex;
  align-items: center;
  cursor: pointer !important;
  justify-content: space-between;
  margin-bottom: 15px;
  .link-icon {
    height: 100%;
  }
}
</style>
